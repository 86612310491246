<template>
  <div class="buttonsContainer">
    <div v-for="button in block.buttons" :key="button.id" class="column is-6">
      <ExternalButton :block="block" :button="button"/>
    </div>
  </div>
</template>


<script>
import ExternalButton from "./ExternalButton.vue";

export default {
  components: {
    ExternalButton
  },
  props: {
    block:{
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      touchDown: false,
    };
  },
  computed: {},
  methods: {
  },
  mounted() {
    //
  },
};
</script>

<style scoped lang="scss">
.buttonsContainer{
  display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  // align-items: center;
  // gap: 1em;
}
</style>
