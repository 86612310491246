<template>
    <button class="btn" :class="{touchDown}" @touchstart="TouchStart" @touchend="TouchEnd" oncontextmenu="return false;">
        <div>{{ button.label }}</div>
        <d-icon v-if="button.icon" :icon="button.icon" size="is-small" />
    </button>
  </template>
  
  
  <script>
  export default {
    components: {},
    props: {
      block:{
        type: Object,
        required: true,
      },
      button:{
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        touchDown: false,
      };
    },
    computed: {},
    methods: {
      TouchStart() {
        this.touchDown = true;
        this.EmitButtonState(true)
      },
      TouchEnd(e) {
        e.stopPropagation();
        this.touchDown = false;
        this.EmitButtonState(false)
      },
      EmitButtonState(clickState){
        this.$socket.client.emit('gameMessage', {
          type: 'controllerData',
          controllerType: 'button',
          name: this.button.socketName,
          input: {
            pressed: clickState,
          }
        })
      },
    },
    mounted() {
      //
    },
  };
  </script>
  
  <style scoped lang="scss">
  .btn{
    border: 1px solid #000;
    border-radius: 50%;
    width: 60px;
    height: 60px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.touchDown{
      background-color: #000;
      color: #fff;
    }
  }
  </style>
  